table {
  font-size: 12px;
}

tfoot {
  font-weight: bold;
}

.pedidoPorEntregar-card {
  padding: 0.5rem;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pedidoPorEntregar-card-Pedido {
  text-align: left;
}
.pedidoPorEntregar-card-Folio {
  padding-top: 0.2rem;
  font-weight: bold;
}
.pedidoPorEntregar-card-Nombre {
  font-weight: bold;
}

.pedidoPorEntregar-card-ColoniaMunicipio-Total {
}

.pedidoPorEntregar-card-ColoniaMunicipio {
  display: flex;
  flex-direction: column;
}

.pedidoPorEntregar-card-Total {
  font-weight: bold;
  text-align: right;
}

.pedidoPorEntregar-card-ordenPreparacion {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
}

.pedidosPorEntregar-footer {
  position: fixed;
  height: 2rem;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(245, 168, 24);
  color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#pedidosPorEntregar-count {
  padding-left: 0.5rem;
}
#pedidosPorEntregar-total {
  padding-left: 1rem;
}
.pedidosPorEntregar-container {
}

#pedidosPorEntregarPage-mainFilter {
  display: flex;
  align-items: center;
}
#pedidosPorEntregarPage-btnRefresh {
  height: 30px;
}
