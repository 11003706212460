.recepcionCompra__header h1 {
  font-size: 1.5rem;
}

.recepcionCompra__selectOrdenCompra {
  margin-bottom: 0.3rem;
}

.recepcionCompra__body {
  background: white;
  color: black;
}

.recepcion__partida {
  margin: 0.5rem;
}

.recepcion__partida .card-body {
  padding: 0.7rem;
}

.recepcion__partida-body {
  text-align: left;
  padding: 1rem 0;

  display: grid;
  grid-template-columns: 5rem auto;
}

.recepcion__partida-body img {
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
  text-align: center;
}

.recepcion__partida-column.completada {
  background-color: rgb(231, 253, 196);
}

#recepcion__partida-descripcion {
  font-weight: 600;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
}

.recepcion__partida-two-cols {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-right: 1rem;
}

.recepcion__partida-two-cols__right {
  font-weight: 600;
}

.recepcion__partida-two-cols__span {
  grid-column-start: 1;
  grid-column-end: 3;
}

.red {
  color: red;
}

.green {
  color: green;
}
