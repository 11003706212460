.App {
  text-align: center;
  width: 100%;
  float: left;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  background-color: #fbf9fa;
}
.categoriasPortada {
  display: inline-block;
  width: 16.66%;
  padding: 10px;
  font-size: 16px;
  font-family: "Libre Franklin", sans-serif;
}
.categoriasPortada a {
  color: #000;
}
.categoriasPortadaInt {
  float: left;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  padding: 10px;
  transition: 0.2s linear;
}
.categoriasPortadaInt:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.categoriasPortadaImgCont {
  width: 100%;
  height: 100%;
  padding: 10px;
  float: left;
}
.categoriasPortadaImg {
  width: 100%;
  position: relative;
  transition: all 0.1s linear;
  overflow: hidden;
  padding-bottom: 100%;
  float: left;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.categoriasPortadaTit {
  min-height: 45px;
}

@media screen and (max-width: 1000px) {
  .categoriasPortada {
    width: 25%;
  }
}

@media screen and (max-width: 600px) {
  .categoriasPortada {
    width: 50%;
  }
}

/* prods*/
.productos {
  float: left;
  width: 20%;
  transition: all 0.1s linear;
  padding: 10px;
}

.productosInt {
  float: left;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #fff;
}
.productosTit {
  text-align: center;
  font-size: 16px;
  font-family: "Libre Franklin", sans-serif;
}
.productosImgCont {
  width: 100%;
  height: 100%;
  padding: 10px;
  float: left;
}
.productosImg {
  width: 100%;
  position: relative;
  transition: all 0.1s linear;
  overflow: hidden;
  height: 200px;
  float: left;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.producto-agotado {
  opacity: 0.3;
}

.productosImgImg {
  max-width: 100%;
  max-height: 190px;
  z-index: 99;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.productos:hover .productosImg {
  transform: scale(1.05);
}
.productos:hover .productosInt {
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.productosP {
  border: 1px solid green;
}
.productosP:hover {
  border: 1px solid green !important;
}

.productosClear5 {
  clear: both;
  display: block;
}
.productosClear3 {
  clear: both;
  display: none;
}
.productosClear2 {
  clear: both;
  display: none;
}

@media screen and (max-width: 1000px) {
  .productos {
    width: 33.33%;
  }
  .categoria {
    width: 33.33%;
  }
  .productosClear5 {
    clear: both;
    display: none;
  }
  .productosClear3 {
    clear: both;
    display: block;
  }
  .productosClear2 {
    clear: both;
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .productos {
    width: 50%;
  }
  .categoria {
    width: 50%;
  }
  .productosClear5 {
    clear: both;
    display: none;
  }
  .productosClear3 {
    clear: both;
    display: none;
  }
  .productosClear2 {
    clear: both;
    display: block;
  }
}

/* botones*/
button:focus {
  outline: none;
}
button {
  font-family: "Libre Franklin", sans-serif;
  appearance: none;
}

.botonNotas {
  float: left;
  position: relative;
  cursor: pointer;
  background-color: #f4f4f4;
  border-radius: 3px;
  transition: 0.2s linear;
}
.botonNotas:hover {
  background-color: #ebebeb;
}
.botonNotas .material-icons {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}
.botonNotas .div100 {
  padding: 5px 5px 5px 26px;
}

.botonCerrar {
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border: none;
  background-color: #ebebeb;
  font-size: 15px;
}

input:focus {
  outline: none;
}

.carritoHeader {
  height: 40px;
  line-height: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  transition: linear 0.2s;
  background-color: #fff;
  font-family: "Libre Franklin", sans-serif;
  padding: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
}
.carritoHeader a {
  color: #000;
}

.carritoHeaderPortada a {
  color: #333 !important;
}
.carritoHeader:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.carritoHeader a:hover {
  color: #ff5f06;
}
.carritoHeader .div100 {
  padding: 0 0 0 30px;
}
.carritoHeader .material-icons {
  position: absolute;
  left: 5px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}

.botonAgregar {
  background-color: #f4f4f4;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: linear 0.2s;
}
.botonAgregar:hover {
  background-color: #48944f;
  color: #fff;
}
.botonAgregar:hover:disabled {
  background-color: #f4f4f4;
  color: rgba(16, 16, 16, 0.3);
  cursor: pointer;
}
.botonAgregar .div100 {
  padding: 0 0 0 20px;
}
.botonAgregar .material-icons {
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}

.botonCant {
  width: 100%;
  font-size: 14px;
  position: relative;
  padding: 0 10px 0 10px;
}
.botonCant1 {
  float: left;
  font-size: 14px;
  cursor: pointer;
  background-color: #f4f4f4;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 100px;
  border: none;
}
.botonCant1:hover {
  background-color: #ebebeb;
}
.botonCantT {
  float: left;
  cursor: default;
  line-height: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 100px;
  border: none;
}

.botonCantDel {
  float: right;
  cursor: pointer;
  background-color: #f4f4f4;
  height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 100px;
  border: none;
}
.botonCantDel:hover {
  color: #fff;
  background-color: red;
}

a {
  text-decoration: none;
}
/* menu */
.menuElemento {
  float: left;
  color: #000;
  padding: 5px;
}
.menuElemento a {
  color: #000;
  border-radius: 4px;
  background-color: #ccc;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  padding: 10px;
}
.menuElemento a:hover {
  background-color: #ff5f06;
  color: #fff;
}

.menuElementoP a {
  color: #fff;
  border-radius: 4px;
  background-color: #ff5f06;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  padding: 10px;
}

.inputBuscar {
  font-family: "Libre Franklin", sans-serif;
  height: 40px;
  padding: 6px;
  margin-top: 0px;
  margin-right: 10px;
  width: 150px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: 0.2s linear;
}
.inputBuscar:hover {
  border: 1px solid #666;
}
.inputBuscar:focus {
  border: 1px solid #666;
}
@media screen and (max-width: 800px) {
  #menuCats {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    float: right;
  }
  .menuElemento {
    float: none;
    display: inline-block;
  }
  .inputBuscar {
    width: 100px;
  }
  .carritoHeader {
    font-size: 11px;
    margin-right: 5px;
  }
}
/* menu */

/* portadita */
.proceso {
  transition: 0.2s linear;
  width: 300px;
  display: inline-block;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  margin: 5px;
}
.proceso:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.procesoTit {
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 5px;
}

.ventajas {
  width: 25%;
  float: left;
  padding: 20px;
}
.ventajasInt {
  transition: 0.2s linear;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  float: left;
}
.ventajasTit {
  font-family: "Libre Franklin", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}
.ventajas .div100 {
  white-space: normal;
  margin-top: 10px;
}
.ventajas:hover .ventajasInt {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 600px) {
  #lasVentajas {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ventajas {
    width: 250px;
    display: inline-block;
    float: none;
    vertical-align: top;
  }
}
/* portada */

/* banners */
.bannerPortada {
  width: 100%;
  font-size: 18px;
  float: left;
  font-weight: 800;
  background-size: cover;
  line-height: 80px;
  background-repeat: no-repeat;
  padding: 120px 10px 160px 10px;
  color: #fff;
  text-shadow: #000 0 0 10px;
}
.bannerProductos {
  width: 100%;
  font-size: 18px;
  float: left;
  font-weight: 800;
  background-size: cover;
  line-height: 80px;
  background-repeat: no-repeat;
  padding: 150px 10px 50px 10px;
  color: #fff;
  text-shadow: #000 0 0 10px;
}

@media screen and (max-width: 600px) {
  .bannerPortada {
    padding: 130px 10px 160px 10px;
  }
}
/* banners */

/* formas */
.inputError {
  color: red;
  font-size: 12px;
}
/* formas */
.naranja {
  color: #ff5f06;
}
.verde {
  color: #48944f;
}

.titulos80 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 50px;
  line-height: 70px;
}
.titulos50 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 50px;
}
.negrita {
  font-weight: 600;
}

.titulos40 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 40px;
}
.titulos30 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 30px;
}
.titulos20 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 20px;
}

h3 {
  font-family: "Libre Franklin", sans-serif;
  font-size: 30px;
  color: #48944f;
}

@media screen and (max-width: 600px) {
  .bannerPortada .titulos80 {
    font-size: 40px;
    line-height: 40px;
  }
}

/* header & foots */
.foots {
  background-color: #0a0e1a;
  color: #fff;
}
.redes {
  float: right;
  padding: 10px;
}
.redes a {
  color: #fff;
  opacity: 0.8;
}
.redes a:hover {
  opacity: 1;
}
.superWhats {
  position: fixed;
  bottom: 10%;
  color: #fff;
  padding: 10px;
  border-radius: 100px;
  right: 10px;
  z-index: 9999;
  /*background-color: #075e54;background-repeat: no-repeat;background-position: center;*/
}
.superWhats a {
  color: #fff;
}
.superWhats:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.header {
  width: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  height: 100px;
  padding: 10px 0 10px 0;
  z-index: 9999;
  top: 0;
}

.headerLogo {
  position: absolute;
  left: 0;
}
#headerMens {
  width: 100%;
  padding-left: 100px;
  float: right;
}
.headerP {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.headerPortada {
  width: 100%;
  position: absolute;
  height: 100px;
  padding: 10px 0 10px 0;
  z-index: 999;
  top: 0;
}

.seguir {
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: #ccc;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 30px;
  padding: 5px 10px;
  margin: auto;
  cursor: pointer;
}
.seguir:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.terminar {
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: #48944f;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  padding: 5px 10px;
  width: 90% !important;
  margin: auto;
  cursor: pointer;
}
.terminar:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.terminar:disabled {
  background-color: #989b98;
  color: #ccc;
}
.btn {
  border: 2px solid #fff;
  border-radius: 4px;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  padding: 5px 10px;
  /*width: 90% !important;*/
  margin: auto;
  cursor: pointer;
  margin: 5px 0;
}

.btn.btn-primary {
  background-color: #48944f;
  color: #fff;
}

.btn.btn-danger {
  background-color: #a52c2c;
  color: #fff;
}

.areas {
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: #48944f;
  -webkit-transition: background-color 200ms ease, color 200ms ease;
  transition: background-color 200ms ease, color 200ms ease;
  font-family: "Libre Franklin", sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  padding: 9px 15px;

  float: right;
  cursor: pointer;
  display: none;
}
.areas:hover {
  background-color: #ffff;
  color: #000000;
}
/* header*/

/* checkout*/
.checkoutLinea {
  width: 100%;
  float: left;
  position: relative;
  min-height: 120px;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 5px;
  transition: linear 0.2s;
  text-align: left;
}
.checkoutLinea:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.checkoutLineaText {
  width: 100%;
  float: left;
  padding: 10px 10px 10px 120px;
}
.checkoutLineaTits {
  font-size: 14px;
  font-weight: 600;
  padding-right: 60px;
}
.checkoutLineaImg {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 100px;
  padding: 10px;
  background-color: #fff;
}
.checkoutLineaSubs {
  position: absolute;
  right: 10px;
  top: 10px;
}

#checkoutForm {
  padding: 0 100px 0 100px;
}
#checkoutForm div {
  float: left;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
#checkoutForm .divNo {
  float: left;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: left;
  color: rgba(0, 0, 0, 0.57);
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #999;
  font-weight: 400;
  font-size: 16px;
}
#checkoutForm input {
  width: ;
}

.selects {
  width: 210px;
  margin-left: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 16px;
  border: none;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  #checkoutForm {
    padding: 0 10px 0 10px;
  }

  .checkoutLineaImg {
    width: 80px;
  }
  .checkoutLineaText {
    width: 100%;
    float: left;
    padding: 10px 10px 10px 90px;
  }
}
/* */

/* generales */

div,
input {
  box-sizing: border-box;
}
.left {
  float: left;
}
.right {
  float: right;
}

/*textos */
.centrado,
.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.justificado {
  text-align: justify;
}
.blanco {
  color: #fff !important;
}
.negro {
  color: #000 !important;
}

.sombra {
  text-shadow: #000 0 0 3px;
}
/* */

/* material */

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

.cont {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
.contF {
  width: 100%;
  float: left;
  position: relative;
}

.clear {
  clear: both;
}
.clear5 {
  clear: both;
  height: 5px;
}
.clear10 {
  clear: both;
  height: 10px;
}
.clear15 {
  clear: both;
  height: 15px;
}
.clear20 {
  clear: both;
  height: 20px;
}
.clear30 {
  clear: both;
  height: 30px;
}
.clear50 {
  clear: both;
  height: 50px;
}

.divi {
  width: 100%;
  float: left;
  height: 1px;
  background-color: #ebebeb;
}

.divAuto {
  float: left;
  width: auto;
}

.blanco10 {
  background-color: #fff;
}
.div100 {
  float: left;
  width: 100%;
  position: relative;
}
.div85,
.div85f {
  float: left;
  width: 75%;
}
.div75,
.div75f {
  float: left;
  width: 75%;
}
.div70,
.div70f {
  float: left;
  width: 70%;
}
.div66,
.div66f {
  float: left;
  width: 66.6666%;
}
.div50,
.div50f {
  float: left;
  width: 50%;
}
.div33,
.div33f {
  float: left;
  width: 33.3333%;
}
.div30,
.div30f {
  float: left;
  width: 30%;
}
.div25,
.div25f {
  float: left;
  width: 25%;
}
.div20,
.div20f {
  float: left;
  width: 20%;
}
.div16,
.div16f {
  float: left;
  width: 16.6666666%;
}
.div15,
.div15f {
  float: left;
  width: 15%;
}
.div14,
.div14f {
  float: left;
  width: 14.28%;
}
.div12,
.div12f {
  float: left;
  width: 12.5%;
}
.div11,
.div11f {
  float: left;
  width: 11.11%;
}

.div85C {
  display: table-cell;
  width: 75%;
}
.div80C {
  display: table-cell;
  width: 75%;
}
.div75C {
  display: table-cell;
  width: 75%;
}
.div70C {
  display: table-cell;
  width: 70%;
}
.div66C {
  display: table-cell;
  width: 66.6666%;
}
.div50C {
  display: table-cell;
  width: 50%;
}
.div33C {
  display: table-cell;
  width: 33.3333%;
}
.div30C {
  display: table-cell;
  width: 30%;
}
.div25C {
  display: table-cell;
  width: 25%;
}
.div20C {
  display: table-cell;
  width: 20%;
}
.div16C {
  display: table-cell;
  width: 16.6666666%;
}
.div15C {
  display: table-cell;
  width: 15%;
}
.div14C {
  display: table-cell;
  width: 14.28%;
}
.div12C {
  display: table-cell;
  width: 12.5%;
}
.div11C {
  display: table-cell;
  width: 11.11%;
}

@media screen and (max-width: 900px) {
  .div33 {
    float: left;
    width: 100%;
  }
  .div25 {
    float: left;
    width: 50%;
  }
  .div75 {
    float: left;
    width: 100%;
  }
  .div20 {
    float: left;
    width: 50%;
  }
  .div50 {
    float: left;
    width: 100%;
  }
  .div66 {
    float: left;
    width: 100%;
  }
  .div16 {
    float: left;
    width: 33.33333333%;
  }
}

@media screen and (max-width: 600px) {
  .div50,
  .div20,
  .div25,
  .div33,
  .div66,
  .div75 {
    float: left;
    width: 100%;
  }
  .div16 {
    float: left;
    width: 100%;
  }
  .div80C,
  .div75C,
  .div70C,
  .div66C,
  .div50C,
  .div33C,
  .div30C,
  .div25C,
  .div20C {
    display: block;
    width: 100%;
    float: left;
  }
}

/* padds */
.padd3,
.padd3R {
  padding: 3px !important;
}
.padd5,
.padd5R {
  padding: 5px !important;
}
.padd10,
.padd10R {
  padding: 10px !important;
}
.padd10NR {
  padding: 10px 10px 10px 0;
}
.padd10NL {
  padding: 10px 3px 10px 10px;
}
.padd15,
.padd15R {
  padding: 15px;
}
.padd20,
.padd20R {
  padding: 20px;
}
.padd30,
.padd30R {
  padding: 30px;
}
