.commonInput__wrapper {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 0.5rem;
  border: 1px solid rgb(197, 135, 135);
  border-radius: 6px;
  transition: border-color 0.15s ease-in, box-shadow 0.15s ease-in;
}

.commonInput__wrapper:focus-within {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255/25%);
}

.commonInput__input {
  border: none;
  padding-left: 0;
  width: 100%;
}

.commonInput__input.centered {
  text-align: center;
}

.searchInput__input {
  padding-right: 26px;
}

.searchInput__button {
  background-color: transparent;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.searchInput__buttonIcon {
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

.quantityInput {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.quantityInput .btn {
  padding: 0 1.2rem;
}

.quantityInput .commonInput__wrapper {
  margin: 0 0.3rem;
  flex-direction: column;
  padding: 0.1rem;
  line-height: 1.5;
}

.quantityInput__unidad {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
  text-transform: lowercase;
}
