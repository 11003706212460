.lookupProducto-header {
  width: 100%;
}
.lookupProducto-header h2 {
  font-size: 1.5rem;
}
.lookupProducto-header__searchInput {
  width: 100%;
}

.lookupProducto__list-item {
  min-height: 2rem;
  overflow: scroll;
  margin-bottom: 0.5rem;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 0.5rem;
}

.lookupProducto__list-item.item-selected {
  background: rgb(246, 181, 61);
  border: 1px solid green;
}
