#preciosTable table {
  width: 100%;
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  #preciosTable table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  #preciosTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  #preciosTable td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  #preciosTable td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  #preciosTable td:nth-of-type(1):before {
    content: "Categoria";
  }
  #preciosTable td:nth-of-type(2):before {
    content: "Id";
  }
  #preciosTable td:nth-of-type(3):before {
    content: "Descripción";
  }
  #preciosTable td:nth-of-type(4):before {
    content: "Intervalo";
  }
  #preciosTable td:nth-of-type(5):before {
    content: "Unidad";
  }
  #preciosTable td:nth-of-type(6):before {
    content: "Costo";
  }
  #preciosTable td:nth-of-type(7):before {
    content: "Margen";
  }
  #preciosTable td:nth-of-type(8):before {
    content: "Precio";
  }
}
