.existencias-grid {
  display: grid;
  grid-template-columns: 3fr 1.5fr 1fr;
  text-align: left;
  padding: 0.5rem;
}
.existencias-lista__item {
  border-bottom: 1px dashed silver;
}

.existencias-lista__header {
  background-color: black;
  color: white;
  margin-top: 0.5rem;
}

.existencias-header_container {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.existencias-filters__div {
  padding: 0 0.5rem;
}

.existencias-filter-existencia {
  background: #777;
  border-radius: 20px;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  margin-top: 0.5rem;
  max-width: 60%;
  width: fit-content;
}

.existencias-filter-existencia div {
  padding: 0.2rem 0.5rem;
}
.existencias-filter-existencia__selected {
  border-radius: 20px;
  background: #fff;
  color: #555;
}
