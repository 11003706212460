.pedidosContainer {
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.pedidoCardText {
  font-size: 1rem;
}

.pedidoCardText p {
  padding-top: 0;
  margin: 0 0 0 0;
}

/*
------------------
Section Header
------------------
*/
.sectionHeader {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: 0.4rem;
}

.sectionHeader h5 {
  font-size: 1.25rem;

  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* 
------------------
Cards 
------------------
*/

.cardStyle {
  margin: 0.4rem;
}

.cardStyle .card-title {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
}

.cardStyle .card-body {
  padding: 0.5rem;
  font-size: 0.8rem;
}

.cardStyle .pin {
  width: 1rem;
  height: 1rem;
  position: relative;
  left: auto;
  top: auto;
  margin: 0;
}

.cardStyle-subTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardStyle-subTitle p {
  margin-left: 0.2rem;
}
/*
------------------
Rutas
------------------
*/

#btnEditarRuta {
  width: 3.5rem;
  height: 3rem;
  margin-right: 0.5rem;
}
#btnEliminarRuta {
  width: 3rem;
  height: 3rem;
}

#btnLiquidarRuta {
  width: 3.5rem;
  height: 3rem;
  margin-right: 0.5rem;
}

/*
------------------
Editar Ruta
------------------
*/
.editarRuta-Pedidos .cardStyle-subTitle {
  flex-direction: column;
}

.editarRuta-Pedido-Buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#btnQuitarPedidoRuta {
  width: 3rem;
  height: 3rem;
}

#btnBajarPedidoRuta,
#btnSubirPedidoRuta,
#btnVerPedidoRuta {
  width: 4rem;
  height: 3rem;
}

#editarRuta-Pedido-Titulo {
  display: flex;
}

#editarRuta-Pedido-Titulo-Consecutivo {
  font-weight: bold;
  font-size: 1.1rem;
  width: 7rem;
}

/*
------------------
Pedidos por Asignar
------------------
*/

#pedidosPorAsignar-Domicilio {
  text-align: left;
}

#pedidosPorAsignar .cardStyle-subTitle {
  flex-direction: column;
}

#pedidosPorAsignar-buttons {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

#pedidosPorAsignar-btnVerPedido {
  margin-right: 0.2rem;
}

#pedidosPorAsignar-btnNavegacion {
  margin-right: 0.2rem;
}

/*
------------------
VerPedido
------------------
*/

.verPedidoRuta-Partida {
  display: flex;
  margin-bottom: 0.2rem;
  flex-direction: row;
}

.verPedidoRuta-Partida div {
  display: flex;
}

#verPedidoRuta-Partida-Detalles {
  flex-direction: column;
  width: 100%;
}

#verPedidoRuta-Partida-Detalles-1 {
  justify-content: space-between;
}

#verPedidoRuta-Partida-Detalles-2 {
  justify-content: flex-end;
}

#verPedidoRuta-Partida-Descripcion {
  margin-left: 0.5rem;
  max-width: 60%;
}
#verPedidoRuta-Partida-Importe {
  font-weight: bold;
  justify-content: flex-end;
  text-align: right;
}

#verPedidoRuta-Partida-Image {
  width: 5rem;
  height: 5rem;
}

#verPedidoRuta-Partida-Image img {
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
}

#verPedidoRuta-Pedido-SubTitle {
  flex-direction: column;
}

#verPedidoRuta-Pedido-Notas {
  background-color: salmon;
  font-weight: bold;
  padding: 0.5rem;
}
#verPedidoRuta-Pedido-Buscar {
  margin-top: 0.5rem;
  margin-left: 0.4rem;
}
#verPedidoRuta-Pedido-Buscar-Input {
  width: 100%;
}

#verPedidoRuta-Pagos {
  display: flex;
  justify-content: flex-end;
}

.verPedidoRuta-Totales {
  font-size: large;
  font-weight: bold;
  text-align: right;
}

.align-right {
  text-align: right;
}

.color-diferencia {
  color: orange;
}

.color-ok {
  color: green;
}

.color-rojo {
  color: red;
}
/*
------------------
MisRutas
------------------
*/
#misRutas-Rutas {
  margin: 0.3rem 0.5rem;
}
#misRutas-Ruta {
  margin-bottom: 0.4rem;
}

#misRutas-Ruta-Titulo {
  display: flex;
}
#misRutas-Ruta-Titulo1 {
  margin-left: 0.2rem;
  font-weight: bold;
}

#misRutas-Ruta-Titulo2 {
  text-align: left;
}

#misRutas-Ruta-Pin {
  width: 20px;
  height: 20px;
}

/*
------------------
Mi Ruta
------------------
*/
#miRuta-Modal {
  height: 98%;
}

#miRuta-Pedidos {
  overflow: scroll;
  height: 20rem;
}

#miRuta-Pedido {
  padding: 0.5 rem;
  margin-bottom: 0.8rem;
}

.miRuta-PedidoEntregado-bg {
  background-color: red;
}

#miRuta-Pedido-Titulo {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

#miRuta-Pedido-Domicilio {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

#miRuta-Pedido-Domicilio p {
  margin-bottom: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
}

#miRuta-Pedido-Botones {
  padding: 0.5rem;
}
#miRuta-Pedido-Botones button {
  margin-left: 0.5rem;
}

/*
------------------
Entregar Pedido
------------------
*/

#entregarPedido-Domicilio {
  display: flex;
  justify-content: space-between;
}

#entregarPedido-Partidas {
  overflow: scroll;
  height: 15rem;
}

#entregarPedido-Partida {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  padding-top: 0.2rem;
  border-bottom: 1px solid silver;
}

#entregarPedido-Partida-Producto {
  display: flex;
}

#entregarPedido-Partida-Image {
  width: 5rem;
  height: 5rem;
}

#entregarPedido-Partida-Image img {
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
}

#entregarPedido-Partida-CantidadImporte {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#entregarPedido-Partida-Importe {
  min-width: 30%;
  text-align: right;
}

#entregarPedido-Partida-Entregado-Importe {
  display: flex;
}

#entregarPedido-Partida-Entregado {
  display: flex;
  height: 2.3rem;
}

#entregarPedido-Partida-CantidadSolicitada {
  min-width: 30%;
  text-align: left;
}
.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}
/*
------------------
LiquidarRuta
------------------
*/
#LiquidarRuta .modal-body {
  padding: 0.5rem;
}

#LiquidarRuta .card {
  margin-bottom: 0.5rem;
}

#LiquidarRuta-Ruta {
  display: flex;
  font-weight: bold;
  line-height: 0rem;
  padding: 0.5rem;
}

#LiquidarRuta-Ruta div {
  margin-top: 0.7rem;
}

#LiquidarRuta #ruta-numero {
  font-size: 2rem;
  margin-right: 0.5rem;
  margin-top: 1.4rem;
}

#LiquidarRuta-Pedidos {
  font-size: small;
  padding: 0;
}

#LiquidarRuta-Totales {
  padding: 0.5rem;
}

.tablaTotales {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: right;
  font-weight: bold;
}

.tablaTotales > div {
  display: flex;
  justify-content: space-between;
}

.tablaTotales-value-bordertop {
  border-top: 1px solid;
}

/* 
------------------
LiquidarRutaPedido
------------------
*/
.liqPedido {
  margin-bottom: 8px;
  padding: 6px;
  border: #ecbd55 1px solid;
  border-radius: 5px;
  font-size: 0.9rem;
}
.liqPedido-heading {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.liqPedido-NoEntregado {
  background: #f8b79d;
}

.liqPedido-heading input[type="checkbox"] {
  background: red;
  transform: scale(1.5);
}

.liqPedido-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, auto);
  justify-content: space-around;
  align-content: center;
}
.liqPedido-titulo {
  margin-bottom: 2px;
  font-weight: 400;
  text-align: right;
}

.liqPedido-monto {
  font-weight: 700;
  text-align: right;
  margin-bottom: 2px;
}

.monto-cxc {
  color: rgb(219, 52, 22);
}

.liqPedidoTotales {
  margin-bottom: 8px;
  padding: 6px;
  border: #ecbd55 1px solid;
  border-radius: 5px;
}
.liqPedidoTotales-grid {
  display: flex;
  flex-direction: column;
}

.liqPedidoTotales-grid div {
  display: flex;
  justify-content: flex-end;
}

.liqPedidoTotales-grid p {
  margin-top: 0;
  margin-bottom: 2px;
}

.liqPedidoTotales-titulo {
  font-weight: 400;
  text-align: right;
}

.liqPedidoTotales-monto {
  margin-left: 5px;
  font-weight: bold;
  text-align: right;
  width: 5rem;
}

.liqPedidoTotales-subTitulo {
  margin: 1.5rem 0px;
  text-align: center;
}
