.pin {
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pintext {
  font-size: larger;
  color: red;
  font-weight: bold;
}
