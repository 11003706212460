#comprasPage {
}
#comprasPage_Header {
}
#comprasPage_Header h1 {
  padding-top: 0.2rem;
  font-size: large;
}
#comprasPage_ProductsList {
  margin: 0;
  padding-bottom: 10rem;
  overflow-y: scroll;
}

#comprasPage_Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(86, 196, 78);
  color: white;
  text-align: center;
  max-height: 2rem;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 0 1rem;
  vertical-align: middle;
}

#comprasPage_Footer_Total {
  padding-top: 0.2rem;
}
.comprasPage_Card {
  margin: 0.3rem;
}

.comprasPage_Card_Content {
  display: flex;
}

#comprasPage_Card_Content_imgDiv {
  width: 25%;
}

.comprasPage_Card_Content img {
  max-width: 100%;
}

#comprasPage_Card_Content_Producto {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0.5rem;
}
#comprasPage_Card_Content_Producto #descripcion {
  text-align: left;
  font-size: medium;
  font-weight: bold;
}
#comprasPage_Card_Content_Producto #cantidad {
  text-align: left;
}

#comprasPage_Card_Content_Producto .cantidadPendiente {
  font-size: medium;
  font-weight: 600;
}
#comprasPage_Card_Content_Producto .cantidadSurtida {
  font-size: small;
}

#comprasPage_Modal_Body img {
  max-width: 100%;
  max-height: 200px;
}

#comprasPage_Modal_Cantidad {
}

#comprasPage_Modal_Cantidad input {
  vertical-align: middle;
  text-align: center;
  width: 5rem;
}

#comprasPage_Modal_Footer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#comprasPage_Modal_Footer .inputError {
  font-size: medium;
}

#comprasPage_Modal_Footer_buttons {
  display: flex;
  justify-content: space-between;
}
