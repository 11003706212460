.lista-partidas {
  text-align: left;
  padding: 15px 10px;
  margin-bottom: 3rem;
  padding-bottom: 150px; /* Para librar el fixed-footer en scroll*/
}

.fixed-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: #353a3f;
  padding: 0.5rem;
}

.fixed-footer textarea {
  margin-bottom: 0.5rem;
}

.backoffice-header {
  background-color: #353a3f;
  color: white;
  padding: 6px;
}
.backoffice-header h1 {
  font-size: 1.5rem;
}

.fixed-header {
  position: -webkit-sticky;
  position: sticky;

  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
}

/* InventariosFisicos 
*/
.lista-inventarios-fisicos {
  font-size: 1.1rem;
  padding: 1rem 0.8rem;
}
.inventarios-fisicos-header_container {
  padding-bottom: 0;
}
.inventarios-fisicos-header {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 1rem;
}

.inventarios-fisicos-filter a {
  color: white;
}

.lista-inventarios-fisicos .card {
  margin-bottom: 0.5rem;
}

.lista-inventarios-fisicos_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.lista-inventarios-fisicos__fecha {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lista-inventarios-fisicos__buttons {
  justify-self: end;
  grid-column: 2;
}
.lista-inventarios-fisicos__buttons button {
  margin-top: 0.5rem;
  margin-right: 1rem;
}
/* 
InventarioFisico
*/

.inventario-fisico-header {
  display: grid;
  grid-template-columns: 24px auto;
  padding-right: 1rem;
}

.inventario-fisico__buscar-agregar {
  display: flex;
  margin: 0.2rem 0 0 0;
}

.inventario-fisico_agregar-button {
  margin-left: 0.2rem;
}

.inventarioFisicoPartida {
  margin: 0.5rem 0;
}
.inventarioFisicoPartida .card-body {
  padding: 1rem 0.5rem;
}
.inventarioFisicoPartida__body {
  display: grid;
  grid-template-columns: 5rem auto;
}

.inventarioFisicoPartida__body img {
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
}
.inventarioFisicoPartida__body_details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}

.inventarioFisicoPartida__body_descripcion {
  font-weight: bold;
}
.inventarioFisicoPartida__body_cantidad {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.inventarioFisicoPartida__body_cantidad .btn {
  padding: 0 1.2rem;
}
.inventarioFisicoPartida__body_cantidad .form-group {
  padding: 0 0.3rem;
}
.inventarioFisicoPartida__body_cantidad .form-control {
  height: 3.2rem;
}

.inventarioFisicoPartida__body_cantidad_unidad {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.5rem;
  padding-top: 1rem;
}

.inventarioFisicoPartida__body_cantidad .form-group {
  margin: 0;
}

.inventarioFisicoPartida__body_cantidad_diferencia {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.inventarioFisicoPartida__diferencia-positiva {
  color: green;
  font-weight: 600;
}
.inventarioFisicoPartida__diferencia-negativa {
  color: red;
  font-weight: 600;
}
